<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <loader/>
      <div class="newInvoice">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
            <v-row style="margin-top:0 !important;margin-bottom:0 !important">
              <v-col cols="12" sm="12" xl="12" md="12" lg="12" class="my-1 py-0">
                <v-card class="card-shadow py-1 px-4 invoice" style="margin-bottom:3rem !important">
                  <v-form class="pt-0" enctype="multipart/form-data" v-model="valid" ref="form">
                    <v-row style="display:flex;align-items:center;justify-content:space-between">
                      <v-col cols="6"  style="display:flex;justify-content:flex-start;align-items:center;padding-bottom:0 !important;margin-bottom:0 !important">
                   <!--      <v-switch
                          v-model="individualchecked"
                          label="Individual Bookings"
                          @change="changeStatus(item)"
                        ></v-switch> -->
                        <v-switch
                          v-model="custominvoice"
                          label="Custom Invoice"
                          class="mx-3"
                        ></v-switch>
                        <v-switch
                          v-model="includevat" 
                          v-if="custominvoice"
                          label="Include VAT ?"
                          class="mx-3"
                        ></v-switch>
                      </v-col>
                      <v-col
                        cols="2"
                        >
                        <v-btn
                            depressed
                            color="primary darken-1"
                            :loading="loading"
                            :disabled="!valid"
                            @click="fetchinvoice"
                            style="float:right"
                            v-if="!custominvoice"
                          >
                            Search
                          </v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3">
                      <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          class="mb-3"
                         >
                         <date-range-picker
                            ref="picker"
                            :showWeekNumbers="showWeekNumbers"
                            :showDropdowns="showDropdowns"
                            :autoApply="autoApply"
                            opens="right"
                            :always-show-calendars="alwaysshowcalendars"
                            v-model="dateRange"
                            @select="updateValues"
                            >
                              <template v-slot:input="picker" style="min-width: 350px;">
                                  <v-text-field
                                    label="Select date range*"
                                    :rules="fieldRules"
                                    v-model="rangedate"
                                    hide-details
                                  ></v-text-field>
                              </template>
                          </date-range-picker>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                        <v-autocomplete
                          :items="['Client', 'Delivery']"
                          label="Select request category*"
                          v-model="item.request_category"
                          :rules="fieldRules"
                          :search-input.sync="searchrequestcategory"
                          clearable
                          hide-details
                          @click:clear="item.request_category = ''"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                          <v-text-field
                            label="Invoice #"
                            v-model="item.invoice_no"
                            required
                            :rules="fieldRules"
                            hide-details
                          ></v-text-field>
                      </v-col>
                      
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                          <v-autocomplete
                            :items="organisations"
                            label="Select organisation*"
                            v-model="item.organisation_id"
                            :value="item.organisation_id"
                            item-text="name"
                            item-value="id"
                            :rules="fieldRules"
                            clearable
                            :search-input.sync="searchorg"
                            multiple
                            chips
                            hide-details
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removeorganisation(data.item)"
                              >
                                {{ data.item.name.substring(0,8)+".." }}
                              </v-chip>
                            </template>
                          </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                        <v-autocomplete
                          :items="projects"
                          label="Select project"
                          v-model="item.project_id"
                          :value="item.project_id"
                          item-text="name"
                          item-value="id"
                          clearable
                          :search-input.sync="searchproject"
                          multiple
                          chips
                          hide-details
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removeproject(data.item)"
                            >
                              {{ data.item.name.substring(0,8)+".." }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                        <v-autocomplete
                          :items="customers"
                          label="Passenger Name"
                          v-model="item.customer_id"
                          item-text="name"
                          item-value="id"
                          :search-input.sync="customer"
                          multiple
                          chips
                          hide-details
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removecustomer(data.item)"
                            >
                              {{ data.item.name.substring(0,8)+".." }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Invoice date*"
                              :rules="fieldRules"
                              :value="timepickerdate"
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                              @click:clear="date = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            scrollable
                            @input="menu = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Due date*"
                              :rules="fieldRules"
                              :value="timepickerduedate"
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="duedate = null"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="duedate"
                            no-title
                            scrollable
                            @input="menu2 = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="menu2 = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="$refs.menu2.save(duedate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          class="mb-3"
                        >
                          <v-text-field
                            label="Subject"
                            v-model="item.subject"
                            hide-details
                          ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
              <!--     <v-row class="my-5">
                    <v-divider></v-divider>
                  </v-row> -->
                  <v-row class="my-0" style="display:flex;align-items:center;justify-content:space-between;margin-top:2rem !important;height:35px" v-if="!custominvoice">
                    <v-col cols="12" md="8" class="mx-3 mt-4 py-0 d-flex align-center">

                      <label class="form-checkbox" style="font-size:.7rem !important;color:#333;text-transform:uppercase;margin-right:1rem">
                        <input type="checkbox" v-model="selectAll" @click="select">
                        Select All
                      </label>

                      <v-btn
                        depressed
                        color="grey lighten-2"
                        v-if="selected.length > 0"
                        style="margin-right:2rem"
                        rounded
                        @click="updateselected"
                      >
                        Transfer records
                      </v-btn>

                    </v-col>
                    <v-col
                        cols="12"
                        md="2"
                      >
                      <v-text-field
                        v-model="search"
                        label="Search"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pb-5" v-if="!custominvoice">
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="mt-4"
                      >
                        <v-virtual-scroll
                          :items="bookings"
                          :item-height="20"
                          renderless 
                          id="virtualscroll"
                          class="invoice"
                         >
                          <template v-slot:default="{ item }">
                              <div class="tcell">
                                <label class="form-checkbox">
                                  <input type="checkbox" :value="item.id" v-model="selected">
                                  <i class="form-icon"></i>
                                </label>
                              </div>
                              <div class="tcell" @click="bookingdetailsupdate(item.id)">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-if="item.passenger_name !== null" v-bind="attrs"
                                      v-on="on">
                                      {{ item.passenger_name }}
                                    </span>
                                  </template>
                                  <span>
                                    <span v-if="item.passenger_name !== null">
                                      {{ item.passenger_name }}
                                    </span>
                                  </span>
                                </v-tooltip>
                              </div>
                              <div class="tcell">
                                <div>{{ item.telephone }}</div>
                              </div>
                              <div class="tcell">{{ item.pickup_date }} {{ item.pickup_time }}</div>
                              <div class="tcell">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs"
                                      v-on="on">
                                      {{ item.travelfrom }}
                                    </span>
                                  </template>
                                  <span>
                                    {{ item.travelfrom }}
                                  </span>
                                </v-tooltip>
                              </div>
                              <div class="tcell">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs"
                                      v-on="on">
                                      {{ item.travelto }}
                                    </span>
                                  </template>
                                  <span>
                                    {{ item.travelto }}
                                  </span>
                                </v-tooltip>
                              </div>
                              <div class="tcell">
                                <span v-if="item.vehicle_type_name !== null">{{ item.vehicle_type_name }}</span>
                                <span v-else>N/A</span>
                              </div>
                              <div class="tcell" @click="editdistance(item.id)">{{ item.distance | currency('') }}</div>
                              <div class="tcell">
                                <div v-if="parseInt(item.movement_id) == 0">
                                  <div v-if="item.organisationrate.length > 0">
                                    <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                                    <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                                    <span v-else>{{ item.organisationrate[0].base_rate | currency('') }}</span>
                                  </div>
                                  <div v-else>0.00</div>
                                </div>
                                <div v-else>{{ item.amount | currency('') }}</div>
                              </div>

                              <div class="tcell" @click="expressway(item.id)">
                                {{ item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0) }}
                              </div>

                              <div class="tcell">
                                <div v-if="item.organisation.vat != '0'">
                                  <div v-if="parseInt(item.movement_id) == 0">
                                    <div v-if="item.organisationrate.length > 0">
                                      <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                      <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                      <span v-else>{{ ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                    </div>
                                    <div v-else>0.00</div>
                                  </div>
                                  <div v-else>{{ ((Math.round(item.amount)*(vat != null ? vat.rate : 0))/100) | currency('') }}</div>
                                </div>
                                <div v-else>0.00</div>
                              </div>
                              <div class="tcell">
                                <div v-if="parseInt(item.movement_id) == 0">
                                  <div v-if="item.organisationrate.length > 0">
                                    <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + (item.organisation.vat != '0' ? ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) : 0) ) | currency('') }}</span>
                                    <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + (item.organisation.vat != '0' ? ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) : 0) ) | currency('') }}</span>
                                    <span v-else>{{ (parseInt(item.organisationrate[0].base_rate) + (item.organisation.vat != '0' ? ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) : 0) ) | currency('') }}</span>
                                  </div>
                                  <div v-else>0.00</div>
                                </div>
                                <div v-else>{{ (Math.round(item.amount) + (item.organisation.vat != '0' ? ((Math.round(item.amount)*(vat != null ? vat.rate : 0))/100) : 0) ) | currency('') }}</div>
                              </div>
                              <div class="tcell">
                                <span v-if="item.user_name !== null">
                                  {{ item.user_name }}
                                </span>
                                <span v-else>N/A</span>
                              </div>
                              <div class="tcell">
                                 <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          icon
                                          v-bind="attrs"
                                          small
                                          v-on="on">
                                        <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                      </v-btn>
                                    </template>
                                    
                                    <v-list-item-group
                                      color="primary"
                                      class="actions"
                                      >
                                      <v-list-item
                                          @click="bookingprofile(item.id)"
                                          >
                                              <v-list-item-icon>
                                                <v-icon color="#000000">mdi-account</v-icon>
                                              </v-list-item-icon>
                                              <v-list-item-content>
                                                <v-list-item-title>Profile</v-list-item-title>
                                              </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item
                                          @click="movement(item.id)"
                                          >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-radar</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Change Movement</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item
                                          @click="expressway(item.id)"
                                          >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-highway</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Expressway</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                      <v-list-item
                                        link
                                        @click="waiting(item.id)"
                                        >
                                            <v-list-item-icon>
                                              <v-icon>mdi-clock</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Waitings</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        link
                                        @click="voucher(item.ref_no)"
                                        >
                                            <v-list-item-icon>
                                              <v-icon>mdi-ticket-confirmation</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>E-voucher</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>
                                    </v-list-item-group>
                                  </v-menu>
                              </div>
                          </template>
                        </v-virtual-scroll>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top:2rem !important;" class="pb-5" v-if="custominvoice">
                    <v-col cols="12">
                          <span style="font-size:.95rem;font-weight:500">Items Section</span>
                        </v-col>
                    <v-col cols="12" style="padding:0 !important;margin:0 !important;">
                      <v-row class="mb-4" style="padding:0 !important;margin:0 !important;" v-for="(item, indx) in items" :key="indx">
                        <v-col sm="12" md="5">
                            <v-text-field
                              label="Item Details"
                              v-model="item.details" 
                              :name="`items[${indx}][details]`"
                              required
                              hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="2">
                          <v-text-field
                            label="Qnty"
                            v-model="item.quantity" 
                            :name="`items[${indx}][quantity]`"
                            required
                            size="sm"
                            @input="onQuantityInput($event,indx)"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="2">
                            <v-text-field
                              label="Rate"
                              v-model="item.rate" 
                              :name="`items[${indx}][rate]`"
                              required
                              min="1"
                              @input="onRateInput($event,indx)"
                              hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="2">
                            <v-text-field
                              label="Amount"
                              v-model="item.amount" 
                              :name="`items[${indx}][amount]`"
                              required
                              readonly
                              hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="1" class="addremoveicons mt-1">
                          <v-icon class="mx-2" @click="addItem">mdi-plus</v-icon>
                          <v-icon @click="removeItem(indx)">mdi-close</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-bottom-navigation
                  fixed
                  horizontal
                >
                <div>
                  <v-btn
                      color="#dedede"
                      class="mx-2"
                      style="height:35px !important;border-radius:3px;"
                      :loading="loading1"
                      :disabled="!valid"
                      @click="savedraft"
                    >
                    <span style="font-size:.82rem !important;color:#111 !important">Save as Draft</span>
                  </v-btn>

                  <!-- <v-btn
                    color="#F98525"
                    class="mx-2"
                    style="height:35px !important;border-radius:3px;"
                    :loading="loading1"
                    :disabled="!valid"
                    @click="save"
                  >
                    <span style="font-size:.82rem !important;color:#111 !important">Save and Download</span>
                  </v-btn> -->

                  <v-btn
                      color="#dedede"
                      class="mx-2"
                      depressed
                      link
                      :to="{name:'invoiceList'}"
                      style="height:35px !important;border-radius:3px;"
                    >
                    <span style="font-size:.82rem !important;color:#111 !important">Cancel</span>
                  </v-btn>
                </div>
                <div>
                  <div class="summaryTotals" v-if="!custominvoice">
                    <div class="totals">
                      <span class="font-normal"> SubTotal</span>
                      <span class="font-medium">
                        <p  v-if="calcbookingreports.length > 0">
                            {{

                              (calcbookingreports.reduce( (acc, item) => 
                                {  
                                    let amt = 0;
                                    if(parseInt(item.movement_id) == 0) {
                                      if(item.organisationrate.length > 0) {
                                        if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else {
                                          amt = parseInt(item.organisationrate[0].base_rate)
                                        }
                                      }
                                    }else {
                                      amt = Math.round(item.amount)
                                    }
                                  return acc += amt; 
                                }, 0) + calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0, waitingfee = 0, subamt = 0;
                                if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                  if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                      waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                  }
                                }

                                if(item.waiting.length > 0) {
                                  subamt = item.waiting.reduce((acc1, value) => { 
                                            let total = 0;
                                            if(value.starttime !== null && value.endtime !== null) {
                                              
                                              if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                              }

                                            }

                                            return  acc1 += total
                                          }, 0)
                                }

                                amt = subamt + waitingfee;
                                
                                return acc += amt; 

                              }, 0)) | currency('')

                            }}
                          </p>
                          <p v-else>0.00</p>
                      </span>
                    </div>
                    <div class="mx-5 arrow-right"></div>
                    <div class="totals">
                      <span class="font-normal">Vat({{ vat != null ? vat.rate : 0 }}%)</span>
                      <span class="font-medium">
                        <p v-if="calcbookingreports.length > 0">
                          {{

                              ((((calcbookingreports.reduce( (acc, item) => 
                                {  
                                    let amt = 0;
                                    if(item.organisation.vat !== '0') {
                                      if(parseInt(item.movement_id) == 0) {
                                        if(item.organisationrate.length > 0) {
                                          if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                            amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                          }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                            amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                          }else {
                                            amt = parseInt(item.organisationrate[0].base_rate)
                                          }
                                        }
                                      }else {
                                        amt = Math.round(item.amount)
                                      }
                                    }
                                  return acc += amt; 
                                }, 0) + calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0, waitingfee = 0, subamt = 0;
                                if(item.organisation.vat !== '0') {
                                  if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                    if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                        waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                    }
                                  }

                                  if(item.waiting.length > 0) {
                                    subamt = item.waiting.reduce((acc1, value) => { 
                                              let total = 0;
                                              if(value.starttime !== null && value.endtime !== null) {
                                                
                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                }

                                              }

                                              return  acc1 += total
                                            }, 0)
                                  }

                                  amt = subamt + waitingfee;
                                }
                                
                                return acc += amt; 

                              }, 0))*(vat != null ? vat.rate : 0))/100)) | currency('')

                            }}
                        </p>
                        <p v-else>
                          0.0
                        </p>
                      </span>
                    </div>
                    <div class="mx-5 arrow-right"></div>
                    <div class="totals">
                      <span class="font-normal">Total</span>
                      <span class="font-medium">
                        <p v-if="calcbookingreports.length > 0">
                          {{

                              (calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0, waitingfee = 0, subamt = 0;
                                if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                  if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                      waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                  }
                                }

                                if(item.waiting.length > 0) {
                                  subamt = item.waiting.reduce((acc1, value) => { 
                                            let total = 0;
                                            if(value.starttime !== null && value.endtime !== null) {
                                              
                                              if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                              }

                                            }

                                            return  acc1 += total
                                          }, 0)
                                }

                                amt = subamt + waitingfee;
                                
                                return acc += amt; 

                              }, 0) + calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0;

                                if(item.expressway.length > 0) {
                                  amt = Math.round(item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0))
                                }
                                
                                return acc += amt; 

                              }, 0) + ((((calcbookingreports.reduce( (acc, item) => 
                                {  
                                    let amt = 0;
                                    if(item.organisation.vat !== '0') {
                                      if(parseInt(item.movement_id) == 0) {
                                        if(item.organisationrate.length > 0) {
                                          if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                            amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                          }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                            amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                          }else {
                                            amt = parseInt(item.organisationrate[0].base_rate)
                                          }
                                        }
                                      }else {
                                        amt = Math.round(item.amount)
                                      }
                                    }
                                  return acc += amt; 
                                }, 0) + calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0, waitingfee = 0, subamt = 0;
                                if(item.organisation.vat !== '0') {
                                  if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                    if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                        waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                    }
                                  }

                                  if(item.waiting.length > 0) {
                                    subamt = item.waiting.reduce((acc1, value) => { 
                                              let total = 0;
                                              if(value.starttime !== null && value.endtime !== null) {
                                                
                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                }

                                              }

                                              return  acc1 += total
                                            }, 0)
                                  }

                                  amt = subamt + waitingfee;
                                }
                                
                                return acc += amt; 

                              }, 0))*(vat != null ? vat.rate : 0))/100)) + calcbookingreports.reduce( (acc, item) => 
                                {  
                                    let amt = 0;
                                    if(parseInt(item.movement_id) == 0) {
                                      if(item.organisationrate.length > 0) {
                                        if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else {
                                          amt = parseInt(item.organisationrate[0].base_rate)
                                        }
                                      }
                                    }else {
                                      amt = Math.round(item.amount)
                                    }
                                  return acc += amt; 
                                }, 0)) | currency('')

                            }}
                        </p>
                        <p v-else>
                          0.00
                        </p>
                      </span>
                    </div>
                  </div>
                  <div class="summaryTotals" v-else>
                    <div class="totals">
                      <span class="font-normal"> SubTotal</span>
                      <span class="font-medium">
                        <p v-if="calcitems.length > 0">
                            {{

                              (calcitems.reduce( (acc, item) => 
                              {  

                                return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                              }, 0)) | currency('')

                            }}
                          </p>
                          <p v-else>0.00</p>
                      </span>
                    </div>
                    <div class="mx-5 arrow-right"></div>
                    <div class="totals">
                      <span class="font-normal">Vat({{ vat != null ? vat.rate : 0 }}%)</span>
                      <span class="font-medium">
                        <p v-if="calcitems.length > 0">
                          {{

                            ((((calcitems.reduce( (acc, item) => 
                              {  

                                return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                              }, 0))*(vat != null && includevat == true ? vat.rate : 0))/100)) | currency('')

                          }}
                        </p>
                        <p v-else>
                          0.0
                        </p>
                      </span>
                    </div>
                    <div class="mx-5 arrow-right"></div>
                    <div class="totals">
                      <span class="font-normal">Total</span>
                      <span class="font-medium">
                        <p v-if="calcitems.length > 0">
                          {{

                            (calcitems.reduce( (acc, item) => 
                              {  

                                return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                              }, 0) + ((((calcitems.reduce( (acc, item) => 
                              {  

                                return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                              }, 0))*(vat != null && includevat == true ? vat.rate : 0))/100)) ) | currency('')

                          }}
                        </p>
                        <p v-else>
                          0.00
                        </p>
                      </span>
                    </div>
                  </div>
                </div>

                </v-bottom-navigation>
              </v-col>
            </v-row>
        </v-container>

        <EditDistance v-model="editdistancedialog" :bookingid="bookingid"/>
        <ExpresswayDetail v-model="expresswaydialog" :expresswaydetails="expresswaydetails"/>
        <WaitingDetail v-model="waitingdialog" :waitingdetails="waitingdetails"/>
        <ClientProfile v-model="profiledialog" :profiledetails="profiledetails"/>
        <UpdateDetail v-model="updatedialog" :updatedetails="updatedetails"/>
        <UpdateAll v-model="updatealldialog" :selected="selected"/>
        <MovementDetail v-model="movementdialog" :movementdetails="movementdetails"/>

      </div>
    </div>
</template>
<style>
   .filters .v-input__slot,.filters .v-input.theme--light.v-text-field{
      width:220px !important;
      max-width:220px !important;
    }
    .invoice .v-virtual-scroll__item{
      width:100%;
      display: grid !important;

       /*grid-auto-columns: minmax(4%, 7.691%) !important;*/
       grid-template-columns:50px 10.691% 7.691% 6.691% 12.691% 12.691% 7.691% 4.691% 4.691% 4.691% 5.691% 5.691% 7.691% 5.691%;
       grid-auto-flow: column !important;
       grid-gap: 0px;
    }
</style>
<style scoped>
  .invoice .col-12 {
    padding: 4px 12px !important;
  }
  .invoice .v-list--two-line .v-list-item, .invoice .v-list-item--two-line {
      min-height: 4px !important;
  }
  .v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
    z-index: 99 !important;
  }
  .v-bottom-navigation{
    align-items: center !important;
  }
  .v-virtual-scroll{
      height: 65vh !important;
    }
    .cardtext{
      height: 65vh !important;
    }
</style>
<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '@/services/ability'
import DateRangePicker from 'vue2-daterange-picker'
import $ from 'jquery';
import Vue from 'vue'

import EditDistance from '@/components/subcomponents/editDistance'
import ExpresswayDetail from '@/components/subcomponents/expressway'
import WaitingDetail from '@/components/subcomponents/waiting'
import ClientProfile from '@/components/bookings/submodules/today/clientprofile'
import UpdateDetail from '@/components/subcomponents/updatedetails'
import UpdateAll from '@/components/subcomponents/updateall'
import loader from '@/components/loader'
import MovementDetail from '@/components/subcomponents/movement'

export default {
    name:"newInvoice",
    components: { DateRangePicker,ExpresswayDetail,WaitingDetail,ClientProfile,EditDistance,UpdateDetail,UpdateAll, loader, MovementDetail },
    data(){
        return {
          selected: [],
          selectAll: false,
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],

          daterange: {
              start: '',
              end: ''
            },
          dateRange: {
                      startDate: null,
                      endDate: null
                     },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          rangedate: "",
          searchrequestcategory: "",
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu: false,
          duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu2: false,
          searchfilter:'',
          individualchecked: false,
          custominvoice: false,
          includevat: false,
          searchorg:null,
          search:"",
          customer: null,
          searchproject: null,
          valid: true,
          dialog: false,
          updatedialog: false,

          movementdetails: [],
          movementdialog: false,

          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Invoices',
              disabled: false,
              href: '/organisation/invoices',
            },
            {
              text: 'New Invoice',
              disabled: false,
              href: '',
            },
          ],
          fields: [
            { text: '', value: '' },
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            // { text: 'Project', value: 'project' },
            { text: 'Date', value: 'date' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            // { text: 'Stop', value: 'stop' },
            { text: 'VehicleType', value: 'vehicletype' },
            { text: 'Distance', value: 'distance' },
            { text: 'Cost', value: 'cost' },
            { text: 'Xway', value: 'expressway' },
            { text: 'Vat', value: 'vat' },
            { text: 'Total', value: 'total' },
            // { text: 'Status', value: 'status' },
            { text: 'Bookedby', value: 'bookedby' },
            // { text: 'Approver', value: 'approver' },
            { text: 'Action', value: 'action' },
           ],
          item:{
             organisation_id: [],
             project_id: [],
             customer_id: [],
             invoice_no: "",
             subject: "",
             request_category: "",
          },
          items: [
            {
              details: "",
              quantity: "1",
              rate: "0",
              amount: "0"
            }
          ],
          isBusy: false,
          loading: false,
          loading1: false,
          disabled: false,
           // organisations: [],
           allcustomers: [],
           expresswaydialog: false,
           editdistancedialog: false,
           bookingid: null,
           expresswaydetails: [],
           waitingdialog: false,
           waitingdetails: [],
           profiledetails: [],
           profiledialog: false,
           updatedetails: [],
           updatedialog: false,
           updateall: [],
           updatealldialog: false,
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = 'New Invoice'

      this.$store.state.invoicebookings = []

      this.getData()

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)
    },
    computed: {
      vat() {
        return this.$store.state.vat
      },
      organisations() {
        return this.$store.state.reportorganisations
      },
      allbookings() {
        return this.$store.state.invoicebookings
      },
      bookings() {
        return this.allbookings.filter(item => {
          this.start = 0
          let name = "";
          if(item.passenger_name != null && item.passenger_name != 'null') {name = item.passenger_name}
            
          return name.toLowerCase().includes(this.search.toLowerCase()) || item.travelfrom.toLowerCase().includes(this.search.toLowerCase()) || item.travelto.toLowerCase().includes(this.search.toLowerCase()) || item.ref_no.includes(this.search)
           // || item.passengers.some(item => item.passenger_name.toLowerCase().includes(this.search.toLowerCase()) || item.telephone.includes(this.search))
        })
      },
      timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
      timepickerduedate () {
        return this.duedate ? moment(this.duedate).format('Do MMM YYYY') : ''
      },
      projects() {
        if(this.item.organisation_id.length > 0){
          let projects = []
          let data = this.organisations.filter(organisation =>  this.item.organisation_id.includes(organisation.id) )
          for (let i in data) {
            if(data[i].projects.length > 0) {
              projects.push(...data[i].projects);
            }
          }

          return projects
        }else{
          return []
        }       
      },
      customers() {
        if(this.item.organisation_id.length > 0){
          let data = this.allcustomers.filter(customer =>  this.item.organisation_id.includes(customer.organisation_id) )
          return data
        }else{
          return []
        }       
      },
      calcbookingreports() {
        return this.$store.state.invoicebookings.filter(item => {
          this.start = 0
            if(this.selected.includes(item.id) === true) {
              return item
            }else {
              return false
            }

        })
      },
      calcitems() {
        return this.items
      },
    },
    methods:{

      onQuantityInput(value,index) {
        let found = this.items[index];
        if (found) {
          found.amount = parseFloat(found.quantity)*parseFloat(found.rate)
        }
      },
      onRateInput(value,index) {
        let found = this.items[index];
        if (found) {
          found.amount = parseFloat(found.quantity)*parseFloat(found.rate)
        }
      },

      addItem () {
        this.items.push({
          details: "",
          quantity: "1",
          rate: "0",
          amount: "0"
        })
      },
      removeItem (index) {
        this.items.splice(index, 1);
      },

      select() {
        this.selected = [];
        if (!this.selectAll) {
          for (let i in this.bookings) {
            this.selected.push(this.bookings[i].id);
          }
        }
      },
      bookingprofile(id) {
        this.$store.state.bookingid = id
        this.profiledialog = true
      },
      bookingdetailsupdate(id) {
        this.updatedetails = this.bookings.filter(booking => booking.id === id )
        this.updatedialog = true
      },
      updateselected() {
        this.updatealldialog = true
      },
      editdistance(id) {
        this.bookingid = id
        this.editdistancedialog = true
      },
      movement(id) {
        this.movementdetails = this.bookings.filter(booking => booking.id === id )
        this.movementdialog = true
      },
      expressway(id) {
        this.expresswaydetails = this.bookings.filter(booking => booking.id === id )
        this.expresswaydialog = true
      },
      waiting(id) {
        this.waitingdetails = this.bookings.filter(booking => booking.id === id )
        this.waitingdialog = true
      },
      voucher(ref) {
        window.open(`https://e-voucher.absolutecabs.co.ke/customer/e-voucher/${ref}`, '_blank', 'noreferrer');
      },
      changeStatus(item) {
        this.individualchecked != this.individualchecked
        if(this.individualchecked) {
          this.item.organisation_id = []
          this.item.project_id = []
        }
      },
      removeproject (item) {
        const index = this.item.project_id.indexOf(item.id)
        if (index >= 0) this.item.project_id.splice(index, 1)
      },
      removecustomer (item) {
        const index = this.item.customer_id.indexOf(item.id)
        if (index >= 0) this.item.customer_id.splice(index, 1)
      },
      removeorganisation (item) {
        const index = this.item.organisation_id.indexOf(item.id)
        if (index >= 0) {
          this.item.organisation_id.splice(index, 1)
          this.item.project_id = []
          this.item.customer_id = []
        }
      },
      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.rangedate = this.daterange.start+" - "+this.daterange.end
      },
      async savedraft(){

          if (this.$refs.form.validate()) {

          this.loading1 = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',JSON.stringify(this.item.organisation_id))
          fd.append('project_id',JSON.stringify(this.item.project_id))
          fd.append('customer_id',JSON.stringify(this.item.customer_id))
          fd.append('invoice_no',this.item.invoice_no)
          fd.append('bookings',JSON.stringify(this.selected))
          fd.append('subject',this.item.subject)
          fd.append('date',this.date)
          fd.append('duedate',this.duedate)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)
          fd.append('request_category',this.item.request_category)

          fd.append('items',JSON.stringify(this.items))
          fd.append('custominvoice',this.custominvoice)
          fd.append('includevat',this.includevat)

          axios.post('/record_invoice', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Invoice saved successfully','Success',{position: 'topRight',timeout: 7000,})

              this.clear()
            }
            this.item.project_id = []
              this.item.customer_id = []
              this.item.organisation_id = []

              // this.getData()
              this.$router.push({name:"showInvoice",params:{id:response.data.invoice.id}})

              this.loading1 = false
              this.disabled = false
            })
            .catch(() => {
              this.loading1 = false
              this.disabled = false
            })

          }
      },

      async save(){

          if (this.$refs.form.validate()) {

          this.loading1 = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob'};
          let fd = new FormData();
          fd.append('organisation_id',JSON.stringify(this.item.organisation_id))
          fd.append('project_id',JSON.stringify(this.item.project_id))
          fd.append('customer_id',JSON.stringify(this.item.customer_id))
          fd.append('invoice_no',this.item.invoice_no)
          fd.append('bookings',JSON.stringify(this.selected))
          fd.append('subject',this.item.subject)
          fd.append('date',this.date)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)
          fd.append('duedate',this.duedate)
          fd.append('request_category',this.item.request_category)

          fd.append('items',JSON.stringify(this.items))
          fd.append('custominvoice',this.custominvoice)
          fd.append('includevat',this.includevat)

          axios.post('/record_invoice', fd, config).then(response=>{
           this.loading1 = false
          this.disabled = false

          this.clear()
          this.$store.state.invoicebookings = []

          this.item.project_id = []
          this.item.customer_id = []
          this.item.organisation_id = []

          this.getData()

          this.$toast.success('Invoice created successfully','Success',{position: 'topRight',timeout: 7000,})

           var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
           var fileLink = document.createElement('a');
           fileLink.href = fileURL;
           fileLink.setAttribute('download', 'Invoice.pdf');
           document.body.appendChild(fileLink);
           fileLink.click();
            
          })
          .catch(() => {
            this.loading1 = false
            this.disabled = false
            console.log("Unexpected error occured")
          })

          }
      },

      async fetchinvoice() {

        this.loading = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('organisation_id',JSON.stringify(this.item.organisation_id))
        fd.append('project_id',JSON.stringify(this.item.project_id))
        fd.append('customer_id',JSON.stringify(this.item.customer_id))
        fd.append('request_category',this.item.request_category)
        fd.append('start',this.daterange.start)
        fd.append('end',this.daterange.end)

        await axios.post('/invoice/bookings', fd, config).then(response=>{
            this.loading = false
            if(response.data.status == parseInt(200)) {
              this.$store.dispatch('InvoiceBookings', response.data)
            }
        }).catch(error=>{
            this.loading = false
            console.log(error)
        })
      },
      async getData(){
        this.$store.state.tableloader = true
        await axios.get('/new/invoice').then(response=>{
            this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.reportorganisations = response.data.organisations
              this.allcustomers = response.data.customers
              this.item.invoice_no = response.data.invoice_no

              this.date = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
              this.duedate = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
            }
        }).catch(error=>{
            this.$store.state.tableloader = false
            console.log(error)
        })
      },
  
        clear () {
          if(this.$refs.form != undefined) {
            this.$refs.form.reset()
          }
        
        }
    }
}
</script>